body {
  background-color: white;
  height: 100%;
  margin: 0px;
}

html {
  height: 100%;
}

#__next {
  height: 100%;
}
