.container {
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  background-color: aliceblue;
  height: 100vh;
}

.inline {
  display: flex;
  column-gap: 6px;
}

.example {
  margin: auto;
  width: 75%;
  height: 75%;
  border: 1px solid black;
}

.asset {
  height: 100px;
  width: 100px;
}

.adminLinkContainer {
  margin: 40px 10px 10px 10px;
}

.button {
  margin: 10px;
}

.button:hover {
  cursor: pointer;
}
